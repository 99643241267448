import { lazy, Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import AllRoutes from './FrontEnd/routes/AllRoutes';
import Loader from './components/Loader';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { UI_ROUTES } from './utils/routeConstants';

const AdminRoutes = lazy(() => import('./AdminDashboard/routes/AdminRoutes'));
const EpcRoutes = lazy(() => import('./EpcDashboard/routes/EpcRoutes'));
const CustomerRoutes = lazy(
  () => import('./CustomerDashboard/routes/CustomerRoutes')
);
const AdminProtectedRoutes = lazy(() => import('./AdminProtectedRoutes'));
const EpcProtectedRoutes = lazy(() => import('./EPCProtectedRoutes'));
const CustomerProtectedRoutes = lazy(() => import('./CustomerProtectedRoutes'));
const ManufacturerRoutes = lazy(
  () => import('./ManufacturerDashboard/routes/ManufacturerRoutes')
);
const DsaRoutes = lazy(() => import('./DsaDashboard/routes/DsaRoutes'));
const DSAProtectedRoutes = lazy(() => import('./DSAProtectedRoutes'));
const DistributorRoutes = lazy(
  () => import('./EpcDashboard/routes/DistributorRoutes')
);

const App = () => {
  useEffect(() => {
    // remove all the console logs from production
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
    }
  }, []);
  return (
    <>
      <AllRoutes />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/epc-dashboard/*"
            element={<EpcProtectedRoutes Component={EpcRoutes} />}
          />
          <Route
            path="/distributor-dashboard/*"
            element={<EpcProtectedRoutes Component={DistributorRoutes} />}
          />
          <Route
            path={UI_ROUTES.admin.dashboard}
            element={<AdminProtectedRoutes Component={AdminRoutes} />}
          />
          <Route
            path={UI_ROUTES.manufacturer.dashboard}
            element={<ManufacturerRoutes Component={ManufacturerRoutes} />}
          />
        </Routes>
        <CustomerProtectedRoutes Component={CustomerRoutes} />
        <DSAProtectedRoutes Component={DsaRoutes} />
      </Suspense>
      <ToastContainer />
    </>
  );
};

export default App;
