import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from './slices/loginSlice';
import tempReducer from './slices/tempSlice';
import permissionsReducer from './slices/permissionsSlice';
import authSlice from './slices/authSlice';

// Redux Persist Configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login'] // white-list all 'Persisting' slice
};

// Combining all reducers
const appReducer = combineReducers({
  login: loginReducer,
  temp: tempReducer,
  permissions: permissionsReducer,
  isAuthenticated: authSlice
});

// Root reducer with reset state handling
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    storage.removeItem('persist:root'); // Cleaning the persisted states
    state = undefined; // Resting all in-memory state
  }
  return appReducer(state, action);
};

// Creating persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuring store with persisted reducer and middleware
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false // Disable serializability checks for redux-persist
    })
});

// persister for persisting store state
export const persister = persistStore(store);
