import axios from 'axios';

const APIkey = process.env.REACT_APP_HOSTNAME;
const APIkeyV2 = process.env.REACT_APP_HOSTNAME_v2;

export const submitQuery = async (newData) => {
  try {
    const { data } = await axios.post(`${APIkeyV2}/website/queries`, newData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return data;
  } catch (err) {
    throw new Error(
      err.response?.data?.message ||
        'An error occurred while submitting the query.'
    );
  }
};

export const registerEPC = async (payload) => {
  try {
    const { data } = await axios.post(`${APIkeyV2}/epc/onboard`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
};

export const verifyPanOnBoarding = async (payload) => {
  try {
    const { data } = await axios.post(
      `${APIkeyV2}/epc/onboard/verify-pan`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
};

export const getManufacturersList = async () => {
  try {
    const { data } = await axios.get(`${APIkeyV2}/manufacturers/list`, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw new Error(err.response?.data?.message);
  }
};

export const logout = async () => {
  try {
    const data = await axios.get(`${APIkey}/user/logout`, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const login = async (payload) => {
  try {
    const { data } = await axios.post(`${APIkey}/user/login`, payload, {
      withCredentials: true
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getCitiesByPincode = async (payload) => {
  try {
    const { data } = await axios.post(
      `${APIkey}/fields/filterCityByPincode`,
      payload,
      {
        withCredentials: true
      }
    );
    return data;
  } catch (err) {
    throw Error(err?.response?.data?.message);
  }
};
