export const UI_ROUTES = {
  dashboard: '/',
  login: 'login',
  signup: 'Register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password/:token',
  finance: 'finance',
  registerCustomer: 'RegisterCustomer',
  premiumPartners: 'PremiumPartners',
  cookiePolicy: 'CookiePolicy',
  EPC: 'Epc',
  DSA: 'Dsa',
  aboutUs: 'Aboutus',
  estimator: 'Estimator',
  mySavings: 'MySavings',
  residentialLoan: 'loan-products/residential',
  knowledge: 'knowledge',
  solfinPrivacyPolicy: 'Privacypolicy',
  dhanvikasPrivacyPolicy: 'DhanvikasPrivacypolicy',
  refundPolicy: 'Refundpolicy',
  dhanvikasFairPracticeCodePolicy: 'FairPracticeCode',
  solfinGrievanceRedressalPolicy: 'GrievanceRedressalPolicy',
  termsAndConditions: 'Termsandconditions',
  distributorJoin: 'distributor/join',
  registrationComplete: 'RegistrationComplete',
  custRegistrationComplete: 'cust-registration-complete',
  custRegistered: 'cust-registered',
  partnerRegistrationComplete: 'PartnerRegistrationComplete',
  lease: 'lease',
  comingSoon: 'coming-soon',
  mission: 'mission',
  contactSales: 'contact-sales',
  leaseContactSales: 'lease-contact-sales',
  admin: {
    dashboard: 'admin/*',
    leads: {
      estimatorLeads: 'estimator-leads',
      application: 'application',
      applicationDetailById: 'application/details/:id',
      epcReferredLeads: 'epc-referred-leads',
      epcReferredLeadDetailsByLeadCode: 'epc-referred-leads/detail/:leadCode'
    },
    customers: 'customers',
    customersDetailsById: 'customers/details/:id',
    epc: {
      applications: 'epc-applicants',
      applicantsDetailById: 'epc-applicants/epc-applicants-detail/:id',
      empanelled: 'epc-empanelled',
      empanelledDetailById: 'epc-empanelled/detail/:id'
    },
    distributor: {
      leads: 'distributor-leads',
      salesReviewOrDistributorKyc: 'distributor/kyc',
      salesReviewOrDistributorKycDetailById:
        'distributor-applicants/kyc-detail/:id',
      creditReviewOrDistributorCreditRating: 'distributor/credit-rating',
      empanelled: 'distributor-empanelled',
      empanelledApplicationDetailsById:
        'distributor-applicants/credit-check/:id',
      rejected: 'distributor-rejected'
    },
    dsa: {
      applicants: 'dsa-applicants',
      applicantsDetailById: 'dsa-applicants/detail/:id',
      empanelled: 'dsa-empanelled',
      empanelledDetailById: 'dsa-empanelled/detail/:id'
    },
    channelFinancing: {
      pendingInvoices: 'channelFinancing/invoices/pending',
      verifiedInvoices: 'channelFinancing/invoices/verified'
    },
    projects: 'projects',
    projectDetailById: 'project/detail/:id',
    userRole: 'user-role'
  },
  manufacturer: {
    dashboard: 'manufacturer-dashboard/*',
    comfortLetter: {
      dashboard: '',
      detailsByLetterCode: 'comfort-letters/details/:letterCode',
      signed: 'comfort-letters/signed'
    },
    empanelledEpc: {
      dashboard: 'epc-empanelled',
      epcLeads: 'epc-leads/:epcId'
    }
  },
  NOTFound: '404',
  epc: {
    dashboard: 'epc-dashboard',
    projects: 'projects',
    referredLead: 'referred-leads/:leadType',
    assignedLead: 'assigned-leads',
    addQuoteById: 'assigned-leads/add-quote/:id',
    rateMatrix: 'rate-matrix',
    referredLeadDetails: 'referred-leads/:leadType/detail/:leadCode'
  }
};
