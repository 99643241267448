import { createSlice } from '@reduxjs/toolkit';

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: [],
  reducers: {
    setPermissions: (state, action) => ({
      isAdmin: action.payload?.data?.isAdmin,
      permissions: action.payload?.data?.permissions
    }),
    setDefaultUserRolePermissions: (state, action) => ({
      ...state,
      defaultUserRolePermissions: action.payload
    })
  }
});

export const { setPermissions, setDefaultUserRolePermissions } =
  permissionsSlice.actions;
export default permissionsSlice.reducer;
