import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null
};

const tempSlice = createSlice({
  name: 'temp',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    }
  }
});

export const { setLoading, setError } = tempSlice.actions;

export default tempSlice.reducer;
